$(function () {
  if (window.location.href.indexOf('/commercial/proposal') > -1) {
    initCommercialProposals();
    justifyPendingClient();
    observerRequest();
    calcValue();
    editProposalMode();
    setTimeout(function () {
      editProposalToApprove();
      approvedProposal();
      addButtonToSearch('#form-commercial-proposal #co_owner_id');
    }, 500);
    approvedStatusValidation();


    $('a.delete-proposal-presented').on('click', function(){
      getModalProposalPresentedDelete(this);
   });


   $('#table-additions .delete').on('click', getModalProposalPresentedDelete);
  }
});

function initCommercialProposals() {
  addDefaultSelectOption('.status-search', 'status');
  addDefaultSelectOption('.project-type-search', 'project-type', false);
  addDefaultSelectOption('.user-search', 'user', false);

  // blockNaN('input.proposal-number-search');

  setTimeout(function () {
    $('select#status').trigger('change');

    if ($('#form_of_payment').val()) {
      $('#form_of_payment').trigger('change');
    }
  }, 500);

  $('#date').datetimepicker({
    format: 'DD/MM/YYYY',
    useCurrent: false,
  });

  $('#start').datetimepicker({
    format: 'DD/MM/YYYY',
    useCurrent: false,
  });

  $('#finish').datetimepicker({
    format: 'DD/MM/YYYY',
    useCurrent: false,
  });

  $('#estimate_date').datetimepicker({
    format: 'DD/MM/YYYY',
  });

  $('#date_payment').datetimepicker({
    format: 'DD/MM/YYYY',
    useCurrent: false,
  });

  $('#start').on('dp.change', function (e) {
    const date = $('#finish').val();

    if (e.date) {
      $('#finish, #date_payment').data('DateTimePicker').minDate(e.date);
    }

    if (!date) {
      $('#finish, #date_payment').val('');
    }
  });

  // // Data final nao pode ser menor em relação a data inicial
  $('#finish').on('dp.change', function (e) {
    const date = $('#start').val();

    if (e.date) {
      $('#start').data('DateTimePicker').maxDate(e.date);
    }

    if (!date) {
      $('#start').val('');
    }
  });

  if ($('.commercial-proposals .t-body').toArray().length) {
    resizeTable();
  }

  $.each($('.required-field'), function (key, element) {
    var label = $('label[for="' + $(element).attr('id') + '"]');

    if (label.length > 0 && $(label).children('span').length == 0) {
      label.append("<span class='asterisk-required'> *</span>");
    }
  });

  groupChange();
  client();

  $('#proposal_submitted').on('change', function () {
    if ($(this).prop('checked')) {
      $('a[href="#proposal-presented-tab"]').parent().show();
      return;
    }

    $('a[href="#proposal-presented-tab"]').parent().hide();
  });

  $('#contract').on('change', function () {
    if ($(this).val() === '1') {
      $('a[href="#additive-tab"]').parent().show();
      return;
    }

    $('a[href="#additive-tab"]').parent().hide();
  });

  $('#proposal-attach').on('click', function () {
    $('input[name="proposal_presented_file"]').trigger('click');
  });

  $('input[name="proposal_presented_file"]').on('change', function () {
    proposalPresentedFileStore();
  });

  // $('#type_service_id').on('change', function () {
  //   if ($(this).val() === '0') {
  //     swal({
  //       title: 'Proposta Comercial',
  //       text: 'Envie um email ao administrador do sistema para cadastrar o novo tipo de serviço.',
  //       icon: 'warning',
  //       button: 'Fechar',
  //     });
  //   } else {
  //     const id = $(this).val().trim();

  //     $.ajax({
  //       url: `/project-types/${id}/tasks`,
  //       method: 'GET',
  //       dataType: 'json',
  //       success: (res) => {
  //         const select = $('#task_id');

  //         select.empty();

  //         $.each(res, function (key, value) {
  //           select.append(
  //             $('<option>', {
  //               value: key,
  //               text: value,
  //             })
  //           );
  //         });

  //         select.selectpicker('refresh');
  //       },
  //       error: (error) => {
  //         console.error(error);
  //       },
  //     });
  //   }
  // });

  const inputOwner = $('#owner');

  const approvedStatus = inputOwner.data('approved-status');

  const currentStatus = inputOwner.data('current-status');

  const statusOptions = inputOwner.data('status-options');

  if (approvedStatus) {
    hideOwnerElement(statusOptions[currentStatus], approvedStatus);
  }
  // aqui!!!
  $('select#status').on('change', function () {
    const selectStatus = $(this);

    const statusSelected = selectStatus.val();

    hideOwnerElement(statusOptions[statusSelected], approvedStatus);
    approvedStatusValidation();
    statusRecuseAction(statusSelected);
  });

  var telephoneInput = $('#contact_phone');
  var __ret = telephoneMask(arguments);
  telephoneInput.mask(__ret.SPMaskBehavior, __ret.spOptions);

  if ($('#group_id').data('group-id')) {
    $('#group_id').selectpicker('val', $('#group_id').data('group-id'));
    $('#group_id').trigger('change');
  }

  setTimeout(function () {
    if ($('select#status').data('status')) {
      var value = String($('select#status').data('status'));
      $('select#status').selectpicker('val', value);
    }

    if ($('select#financial_id').data('financial-id')) {
      $('select#financial_id').selectpicker(
        'val',
        String($('#financial_id').data('financial-id'))
      );
    }

    if ($('select#company_id').data('company-id')) {
      $('select#company_id').selectpicker(
        'val',
        String($('#company_id').data('company-id'))
      );
    }

    if ($('select#type_service_id').data('type-service-id')) {
      $('select#type_service_id').selectpicker(
        'val',
        String($('#type_service_id').data('type-service-id'))
      );
    }

    if ($('select#contract').data('contract')) {
      $('select#contract').selectpicker(
        'val',
        String($('#contract').data('contract'))
      );
    }

    if ($('select#user_id').data('user-id')) {
      $('select#user_id').selectpicker(
        'val',
        String($('#user_id').data('user-id'))
      );
    }

    if ($('#proposal_submitted').data('proposal-submit')) {
      $('#proposal_submitted').bootstrapToggle(
        $('#proposal_submitted').data('proposal-submit')
      );
    }

    if ($('#additions').data('additions')) {
      $('#additions').bootstrapToggle($('#additions').data('additions'));
    }

    if (!$('#group_id').selectpicker('val')) {
      $('#group_id').selectpicker('val', 0);
    }

    if (!$('select[name="clients[]"]').selectpicker('val')) {
      $('select[name="clients[]"]').selectpicker('val', -1);
    }
  }, 300);

  $('.update-status-modal').on('click', function () {
    $('#commercial-proposal-edit-modal').modal();
    const { id } = $(this).data();
    $('#commercial-proposal-edit-modal form #commercial-status').selectpicker(
      'val',
      String($(this).attr('data-status'))
    );
    $('#commercial-proposal-edit-modal form #id').val(id);
  });

  $('.btn-update-commercial-proposal').on('click', function () {
    modalEditStatus();
  });

  clickTabContracts();
  temporayBudget();

  // $("#type_payment, #value_payment").parent().hide();

  $('button[data-target="#payment-values"]').parent().hide();

  $('#form_of_payment').on('change', function () {
    const ESPECIFIC = 2;

    if (parseInt($('#form_of_payment').val()) !== ESPECIFIC) {
      $('button[data-target="#payment-values"]').parent().hide();
      return;
    }

    $('button[data-target="#payment-values"]').parent().show();
  });
}

// function blockNaN(selector) {
//   $(selector).on('keydown', function (event) {
//     const permitedKeys = [
//       'Backspace',
//       'ArrowLeft',
//       'ArrowRight',
//       'Delete',
//       'Control',
//       'Shift',
//       'Home',
//       'End',
//       'Enter',
//       'F1',
//       'F2',
//       'F3',
//       'F4',
//       'F5',
//       'F6',
//       'F7',
//       'F8',
//       'F9',
//       'F10',
//       'F11',
//       'F12',
//     ];

//     const key = event.key;

//     console.info(key);

//     const value = Number(key);

//     if (isNaN(value) && !permitedKeys.includes(key)) {
//       event.preventDefault();
//     }
//   });
// }

function addDefaultSelectOption(selector, getParam, selected = true) {
  let params = new URLSearchParams(window.location.search);

  if (selected) {
    let selected = !params.get(getParam) ? 'selected' : '';
  }

  $(selector).prepend('<option value=""' + selected + '>Todos</option>');
  $(selector).selectpicker('refresh');
}

function telephoneMask(arguments) {
  var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11
        ? '(00) 00000-0000'
        : '(00) 0000-00009';
    },
    spOptions = {
      onKeyPress: function (val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      },
    };
  return { SPMaskBehavior: SPMaskBehavior, spOptions: spOptions };
}

function hideOwnerElement(
  currentStatus,
  approvedStatus,
  inputElement = $('#owner, .owner')
) {
  const hasApproved = approvedStatus.includes(`${currentStatus}`);

  if (!hasApproved) {
    inputElement.hide();
  } else {
    inputElement.show();
  }
}

function hideTabsInCommercialProposal() {
  // nav nav-tabs

  if ($('#proposal_submitted').prop('checked')) {
    $('a[href="#proposal-presented-tab"]').parent().show();
  } else {
    $('a[href="#proposal-presented-tab"]').parent().hide();
  }

  if ($('#contract').val() === '1') {
    $('a[href="#additive-tab"]').parent().show();
  } else {
    $('a[href="#additive-tab"]').parent().hide();
  }
}

function groupChange() {
  $('.groups-select').off();

  $('.groups-select').on('change', function () {
    const $dropdown = $(this);

    $.getJSON(
      '/clients/group/' + $dropdown.val() + '?compiled_client=1',
      function (data) {
        const $select = $('.clientes-select');
        $select.find('option').remove();
        $select.append('<option value="-1">PENDENTE</option>');

        $.each(data, function (key, value) {
          $select.append('<option value=' + key + '>' + value + '</option>');
        });
        $select.selectpicker('destroy');
        $select.selectpicker('show');

        if ($('select[name="clients[]"]').data('clients-id')) {
          $('select[name="clients[]"]').selectpicker(
            'val',
            $('select[name="clients[]"]').data('clients-id')
          );
        }
      }
    );
  });

  var groupID = $('.groups-select').selectpicker('val');
  $('.groups-select').selectpicker('val', '');
  $('.groups-select').selectpicker(groupID);
}

function client() {
  $('#add-client').on('click', function () {
    $('#modal-client-commercial').modal();
  });
}

function appendAdditive(entities) {
  let row = '';
  let index = $('#table-additions .t-body table tbody tr').length;
  var formCreate = $('#form-commmercial-proposal');

  $.each(entities, function () {
    formCreate.append(
      `<input name="contract_commercial_id[]" type='hidden' value="${this.id}" />`
    );
    row = `<tr>
                <td>
                    <a href="${this.additive_url_file}" download="${
      this.additive_original_name
    }">${this.additive_original_name}</a>
                </td>
            <td>
            <div class="btn-group ${index <= 3 ? 'dropdown' : 'dropup'}">
            <button type="button" class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="glyphicon glyphicon-cog"></span>
                Opções &nbsp;<span class="caret"></span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
                <li class="divider"></li>

                <li>
                    <a class="delete"  id="/additions/${this.id}/destroy">
                        <span class="glyphicon glyphicon-trash"></span>&nbsp; Remover
                    </a>
                </li>
                <li class="divider"></li>
            </ul>

        </div>
            </td>
        </tr>`;

    $('#table-additions .t-body table tbody').append(row);
    $('#table-additions .delete').on('click', getModalProposalPresentedDelete);
  });
}

function proposalPresentedFileStore() {
  const form = new FormData();
  form.append('_token', $('input[name="_token"]').val());

  if ($('input[name="id"]').val()) {
    form.append('commercial_proposal_id', $('input[name="id"]').val());
  }

  const originalFile = $('input[name="proposal_presented_file"]')[0].files[0];

  const proposalCod = $('input#cod').val();
  const proposalVersion = $('input#version').val();

  if(!checkMaxSizeFile(originalFile)){
    $('input[name="proposal_presented_file"]').val('');
    return;
  }

  if (!proposalVersion) {
    swal({
      icon: 'warning',
      title: 'Versão não encontrada!',
      text: 'Para enviar o arquivo da proposta apresentada, é necessário digitar a versão da proposta no formulário de cadastro.',
      button: 'Fechar',
    });

    $('input[name="proposal_presented_file"]').val('');

    return;
  }
  const [, ext] = originalFile.name.split('.');

  const file = new File(
    [originalFile],
    `P${proposalCod}-${proposalVersion}.${ext}`,
    {
      type: originalFile.type,
    }
  );

  form.append('proposal_presented_file', file);

  $('#status').show();
  $('#preloader').show();

  $.ajax({
    type: 'POST',
    dataType: 'JSON',
    url: '/proposal-presented/store',
    enctype: 'multipart/form-data',
    processData: false,
    contentType: false,
    data: form,
    success: (res) => {
      $('input[name="proposal_presented_file"]').val('');
      appendProposalPresented([res.entity]);
      $('#form-commercial-proposal').append(
        '<input type="hidden" name="proposal_presented[]" value="' +
          res.entity.id +
          '" />'
      );
      $('#status').hide();
      $('#preloader').hide();
    },
    error: (err) => {
      console.log(err);
      $('input[name="proposal_presented_file"]').val('');
      $('#status').hide();
      $('#preloader').hide();
    },
  });
}

function checkMaxSizeFile(file){
  const sizeMB = file.size / 1024 / 1024;

  if(sizeMB < 900){
    return true;
  }

  swal({
    icon:'warning',
    title: 'Arquivo Grande',
    text: 'O sistema não aceita arquivos maiores que 900MB.',
    button: 'Fechar',
  });

  return false;
}

function appendProposalPresented(entities) {
  let row = '';
  let index = $('#table-proposal-presented .t-body table tbody tr').length;

  var formCreate = $('#form-commmercial-proposal');
  $.each(entities, function () {
    formCreate.append(
      `<input name="proposal_presented_id[]" type='hidden' value="${this.id}" />`
    );
    row = `<tr>
                <td>
                    <a href="${this.proposal_presented_url_file}" download="${
      this.proposal_presented_original_name
    }">
                        ${this.proposal_presented_original_name}
                    </a>
                </td>

                <td>
                    <div class="btn-group ${
                      index <= 3 ? 'dropdown' : 'dropup'
                    }">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="glyphicon glyphicon-cog"></span>
                            Opções &nbsp;<span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right">
                <li class="divider"></li>

                <li>
                    <a class="delete-proposal-presented" id="/proposal-presented/${
                      this.id
                    }/destroy">
                        <span class="glyphicon glyphicon-trash"></span>&nbsp; Remover
                    </a>
                </li>
                <li class="divider"></li>
            </ul>

        </div>
            </td>
        </tr>`;
    $('#table-proposal-presented .t-body table tbody').append(row);
    // $('#table-proposal-presented .delete').on('click', getModalDelete);
    $('a.delete-proposal-presented').on('click', function(){
       getModalProposalPresentedDelete(this);
    });
  });
}

async function modalEditStatus() {
  $('#commercial-proposal-edit-modal .circle-icon').show();
  $('#commercial-proposal-edit-modal .circle-icon ~ span').hide();
  $('.btn-update-commercial-proposal').attr('disabled', 'disabled');
  const id = $('#commercial-proposal-edit-modal form #id').val();

  const status = $('#commercial-status').selectpicker('val');
  const STATUS_RECUSE = '7';
  if (status === STATUS_RECUSE) {
    window.location.href = `/commercial/proposal/${id}/edit?status=${STATUS_RECUSE}`;
    return;
  }

  if (status === '4') {
    canApprove(id).then((res) => {
      if (!res) {
        window.location.href = `/commercial/proposal/${id}/edit?try_approve=1`;
      } else {
        saveStatus(id, status);
      }
    });
  } else {
    saveStatus(id, status);
  }
}

async function canApprove(id) {
  const data = await getCommercialProposal(id);

  const { commercialProposal, clients } = data;

  if (!Object.keys(clients).length) {
    return false;
  }

  const isValid = await validateCommercialProposal(commercialProposal);

  return isValid;
}

function getCommercialProposal(id) {
  return new Promise((resolve, reject) => {
    $.ajax({
      method: 'GET',
      url: `/commercial/proposal/${id}/edit`,
      dataType: 'json',
      success: (data) => {
        resolve(data);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
}

async function validateCommercialProposal(obj) {
  const requiredFields = [
    'group_id',
    'version',
    'user_id',
    'contract',
    'amount',
    'date',
    'type_service_id',
    'hours',
    'owner_id',
    'start',
    'finish',
    'company_id',
    'contact',
    'contact_phone',
    'contact_email',
  ];

  for (const field of requiredFields) {
    if (
      !obj.hasOwnProperty(field) ||
      obj[field] === null ||
      obj[field] === ''
    ) {
      return false;
    }
  }

  const payments = await loadPaymentValues(obj.id);

  if (!Array.isArray(payments) || !payments.length) {
    return false;
  }

  return true;
}

async function loadPaymentValues(id) {
  return new Promise((resolve, reject) => {
    $.ajax({
      method: 'GET',
      dataType: 'json',
      url: `/commercial/proposal/${id}/values-payment`,
      success: ({ data }) => {
        resolve(data);
      },
      error: () => {
        resolve([]);
      },
    });
  });
}

function saveStatus(id, status) {
  $.ajax({
    type: 'PUT',
    dataType: 'JSON',
    url: '/commercial/proposal/' + id + '/update',
    data: {
      _token: $('input[name="_token"]').val(),
      status,
      parcial_update: $('[name="parcial-update"]').val(),
    },
    success: ({ entity }) => {
      updateStatus(
        $('a.update-status-modal[data-id="' + id + '"]'),
        status,
        entity
      );

      swal({
        icon: 'success',
        title: 'Proposta Atualizada',
        text: 'O status foi atualizado com sucesso.',
        button: 'Fechar',
      }).then(() => {
        if (status === '4') {
          approvedSweetAlert();
        }

        $('#commercial-proposal-edit-modal').modal('hide');
      });

      $('#commercial-proposal-edit-modal .circle-icon').hide();
      $('#commercial-proposal-edit-modal .circle-icon ~ span').show();
      $('.btn-update-commercial-proposal').attr('disabled', null);
    },
    error: (error) => {
      console.error(error);
      $('#commercial-proposal-edit-modal .circle-icon').hide();
      $('#commercial-proposal-edit-modal .circle-icon ~ span').show();
      $('.btn-update-commercial-proposal').attr('disabled', null);

      // if (error.status === 422 && error.responseJSON.errors?.aproved_params) {
      //   swal({
      //     icon: 'warning',
      //     title: 'Atenção',
      //     text: 'Para o status aprovado é necessário preencher outros campos. Nesse caso use a opção Editar.',
      //     button: 'Fechar',
      //   });

      //   return;
      // }

      swal({
        icon: 'error',
        title: 'Ops Ocorreu um erro!',
        text: 'Tente novamente, caso ao erro persista entre em contato com o administrador.',
        button: 'Fechar',
      });
    },
  });
}

function updateStatus(elementClick, statusId, entity) {
  const [row] = $(elementClick)
    .parent()
    .parent()
    .parent()
    .parent()
    .parent()
    .toArray();
  $(elementClick).attr('data-status', statusId);
  const colStatus = $(row).children()[3];
  const colRecuse = $(row).children()[4];
  const colCodProject = $(row).children()[1];
  $(colStatus).html($('#commercial-status option:selected').html());
  $(colCodProject).html(entity.compiled_cod_project);
  $(colRecuse).html(entity.recuse_note);
}

function clickTabContracts() {
  const params = new URLSearchParams(window.location.search);

  if (params.get('contracts')) {
    $('a[href="#additive-tab"').trigger('click');
  }
}

function temporayBudget() {
  $('[data-consultant]').click(function () {
    const codCommercialProposal = $(
      $(this).parent().parent().parent().parent().parent().find('td')[0]
    ).html();
    $('#preloader').show();
    $('#status').show();
    $($('#type_of_consultant .modal-body .row')[0]).hide();

    Promise.all([loadBudget($(this).attr('data-consultant'))]).then(
      function () {
        $.each(
          $('#type_of_consultant .modal-body table tbody tr'),
          function (index, element) {
            console.log($(element).find('td:last'));
            $(element).find('td:last').html('');
          }
        );
        $('#preloader').hide();
        $('#status').hide();

        $(
          '#type_of_consultant .modal-body #increase_value,  #type_of_consultant .modal-body #discount_value, #type_of_consultant .modal-body #increase_type, #type_of_consultant .modal-body #discount_type'
        ).attr('disabled', 'disabled');
        $(
          '#type_of_consultant .display-flex-start button#attach_document'
        ).attr('disabled', 'disabled');
        $('#type_of_consultant #expense_question').attr('disabled', 'disabled');
        $('#type_of_consultant #note').attr('disabled', 'disabled');
        $(
          '#type_of_consultant #justify_increase, #type_of_consultant #justify_discount'
        ).attr('disabled', 'disabled');
        $('#discount_type, #increase_type, #expense_question')
          .parent()
          .find('button')
          .css('cursor', 'not-allowed');
        $('#type_of_consultant .modal-footer #btn-save').remove();
        $('#type_of_consultant .modal-body').prepend(`
            <div class="row">
                 <div class="col-lg-12 col-xs-12">
                    <p>N° da Proposta: ${codCommercialProposal}</p>
                </div>
            </div>
        `);

        $('#type_of_consultant .modal-body table thead tr th:last').html('');
      }
    );
  });
}

function justifyPendingClient() {
  $('#form-commercial-proposal').on('submit', function (event, send) {
    if (
      !editCompareChanges() ||
      $('#form-commercial-proposal #status').selectpicker('val') !== '5'
    ) {
      return;
    }

    $('#modalAlertDialoglLabel').html('Observação');
    $("label[for='messageDialog']").html(
      'Informe o motivo do cliente ficar pendente'
    );
    $('#modalAlertDialog').modal('show');

    $('#modalAlertDialog .btn-success').on('click', function () {
      const text = $('#messageDialog').val();
      $('input[name="justify_pending_client"]').val(text);
      console.log($('input[name="justify_pending_client"]').val());
      $('#form-commercial-proposal').trigger('submit', [true]);
      $('#preloader').show();
      $('#status').show();
    });

    if (!send) {
      event.preventDefault();
    }
  });
}

function approvedStatusValidation() {
  if ($('#form-commercial-proposal #status').val() != '4') {
    return;
  }

  $('#form-commercial-proposal').off();
  $('#form-commercial-proposal').on('submit', function (event) {
    if (
      !$('#form-commercial-proposal input[name="proposal_presented[]"]').length && $('form #status').val() === '4'
    ) {
      swal({
        icon: 'warning',
        title: 'Atenção',
        text: 'Verifique se foi adicionado uma proposta na guia de propostas apresentadas.',
      });

      event.preventDefault();
    }
  });
}

function observerRequest() {
  $(document).ajaxSuccess(function (event, xhr, settings) {
    console.log('request', { event, xhr, settings });

    const { url } = settings;
    const response = xhr.responseJSON;

    removeProposalPresentIdsAfterDelete(url);
    removeAdditionsIdsAfterDelete(url);

    if (
      url.indexOf('/temporary/budgets/') > -1 &&
      (url.indexOf('update') > -1 || url.indexOf('store') > -1)
    ) {
      var total =
        response.entity.discount_value > 0
          ? response.entity.value_with_discount
          : response.entity.total_with_increase;
      total = total === 0 ? response.entity.value : total;
      total += response.entity.expense_value;
      $('#amount').val(
        new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
          total
        )
      );
      $('#hour').val(response.entity.hours);

      // $('#amount').attr('readonly','readonly');
    }


    // if(url.indexOf('/temporary/budgets/') > -1 && url.indexOf('show') > -1){
    //   $('#amount').attr('readonly','readonly');
    // }
  });
}

function removeProposalPresentIdsAfterDelete(url){
  if(!url.includes('proposal-presented') || !url.includes('destroy')){
    return;
  }

  const id = url.replace(/([a-z /-])/g,'');

  $.each($(`input[name="proposal_presented[]"]`), function(index, element){
      if($(element).val() !== id){
         return;
      }

      $(element).remove();
  });
}

function removeAdditionsIdsAfterDelete(url){
  if(!url.includes('additions') || !url.includes('destroy')){
    return;
  }

  const id = url.replace(/([a-z /-])/g,'');

  $.each($(`input[name="additions[]"]`), function(index, element){
      if($(element).val() !== id){
         return;
      }

      $(element).remove();
  });
}


function calcValue() {
  var PERCENT = 1;
  var VALUE = 2;
  var PERCENT_REF = 100;

  $('#type_payment').on('change', function(){
    $('#value_payment').trigger('keyup');
  });

  $('#value_payment').on('keyup', function () {
    switch (parseInt($('#type_payment').val())) {
      case VALUE:
        break;
      case PERCENT:
        if (
          parseFloat($(this).val().replace(/([.])/g, '').replace(',', '.')) >
          PERCENT_REF
        ) {
          $('#value_payment').parent().addClass('has-error');
          $('#value_payment ~ span strong').html('Valor acima de 100%');
          return;
        }

        $('#value_payment').parent().removeClass('has-error');
        $('#value_payment ~ span strong').html('');
        break;
    }
  });
}

function editProposalMode() {
  if (window.location.href.indexOf('edit') === -1) {
    return;
  }

  $.each(
    $('#form-commercial-proposal input[type="text"]'),
    function (index, element) {
      $(element).attr('data-old-value', $(element).val());
    }
  );

  $.each($('#form-commercial-proposal select'), function (index, element) {
    $(element).attr('data-old-value', $(element).val());
  });
}

function editCompareChanges() {
  const clientPendingId = -1;
  const clientsAtual = $('[name="clients[]"]').data('clients-id')
    ? Array.from($('[name="clients[]"]').data('clients-id'))
    : [];
  clientsAtual.length === 0 ? clientsAtual.push(clientPendingId) : '';

  const hasPendingClient =
    Array.from($('[name="clients[]"]').selectpicker('val') || []).some(
      (id) => Number(id) === clientPendingId
    ) &&
    Boolean(
      Array.from($('[name="clients[]"]').selectpicker('val') || []).length === 1
    );

  const noChangeClient = Array.from(
    $('[name="clients[]"]').selectpicker('val') || []
  ).every((id) =>
    clientsAtual.find((idAtual) => Number(idAtual) === Number(id))
  );

  let inputChanges = false;
  let selectChanges = false;

  $.each(
    $('#form-commercial-proposal input[type="text"]'),
    function (index, element) {
      if (!inputChanges && $(element).attr('id')) {
        console.log({
          actual: $(element).val(),
          old: $(element).data('old-value'),
          element,
        });
        inputChanges = $(element).val() != $(element).data('old-value');
      }
    }
  );

  $.each($('#form-commercial-proposal select'), function (index, element) {
    if (!selectChanges && $(element).attr('id')) {
      selectChanges = $(element).val() != $(element).data('old-value');
    }
  });

  return hasPendingClient && (inputChanges || selectChanges || !noChangeClient);
}

function editProposalToApprove() {
  const params = new URLSearchParams(window.location.search);
  if (params.get('try_approve')) {
    $('select#status').selectpicker('val', '4');
    $('select#status').trigger('change');
  }

  if (params.get('status')) {
    $('select#status').selectpicker('val', params.get('status'));
    $('select#status').trigger('change');
  }
}

function approvedProposal() {
  const params = new URLSearchParams(window.location.search);
  const approved = params.get('commercial_proposal_approved');

  if (approved && approved == true) {
    approvedSweetAlert();
  }
}

function addButtonToSearch(selector) {
  const div = document.createElement('div');
  const divButton = document.createElement('div');
  div.className = 'bs-actionsbox';
  divButton.className = 'btn-group btn-group-sm btn-block';
  const button = document.createElement('button');
  button.innerHTML = 'Remover Seleção';
  button.className = 'actions-btn bs-select-all btn btn-default';
  $(button).on('click', function () {
    $(selector).val('');
    $(selector).selectpicker('refresh');
  });
  divButton.appendChild(button);
  div.appendChild(divButton);

  const dropdownMenu = $(selector).parent().find('.dropdown-menu.open');
  const dropdownInner = $(dropdownMenu).find('.dropdown-menu.inner')[0];
  dropdownInner.parentNode.insertBefore(div, dropdownInner);
}

function statusRecuseAction(status) {
  const STATUS_RECUSE = '7';
  if (STATUS_RECUSE !== status) {
    $('input[name="recuse_note"]').parent().hide();
    $('input[name="recuse_note"]').val('');
    return;
  }

  $('input[name="recuse_note"]').parent().show();
}

function approvedSweetAlert() {
  swal({
    icon: 'info',
    title: 'Proposta comercial',
    text: 'Proposta marcada como aprovada. Um e-mail foi enviado para a administração.',
    button: 'Fechar',
  });

  window.history.replaceState({}, document.title, window.location.pathname);

  $.each($('a.page-link'), function(index, element){
    const url = new URL($(element).attr('href'));
    url.searchParams.delete('commercial_proposal_approved');
    $(element).attr('href', url.href);
  })
}


function getModalProposalPresentedDelete(elem) {
  if ($('#modal-table-details').hasClass('in')) {
      $('#modal-table-details').modal('toggle');
  }

  var distroyitem = $(this).attr('id');

  if (this === this.window) {
      distroyitem = $(elem).attr('id');
  }

  var title = $("#modalAlert .modal-title");
  var menssage = $("#modalAlert .alertMessages");

  title.html("Atenção!");
  menssage.html("Deseja mesmo remover este Item?");

  $('#modalAlert .alert-footer').find('.btn-danger').remove();
  $('#modalAlert .alert-footer').append('<a class="btn btn-danger" href="' + 'javascript:void(0)' + '" id="btn-confirm-exclude"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span> Remover</a>');

  $("#modalAlert").modal("show");

  $('#btn-confirm-exclude').unbind();

  $('#btn-confirm-exclude').click(function() {
      $('#preloader').show();
      $('#status').show();
      $("#modalAlert").modal("hide");
      $.ajax({
          type: 'GET',
          dataType: 'JSON',
          url: distroyitem,
          success: function(res) {
            $(`a[id="${distroyitem}"]`).parent().parent().parent().parent().parent().remove();
            $('#preloader').hide();
            $('#status').hide();
          },
          error: function(err) {
            $("#modalAlert").modal("hide");
            $('#preloader').hide();
            $('#status').hide();
          }
      });
  });
}